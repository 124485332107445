import './Menu.css'
import './garland.css'
import {useNavigate, useLocation} from "react-router-dom"
import {useEffect, useState} from "react"
import {useWindowSize} from "../utils/useWindowSize"
import {api} from "../utils/api"
import {toast} from "react-toastify"
import {Garland} from "./garland";

const maxHeightMenu = 870

export default function Menu ({ userId, userGender, userFirstName, userLastName, lastUpdateTime }) {
    let [activeMenuItem, setActiveMenuItem] = useState(window.location.pathname.replace('/', ''))
    let [viewMenuOnPhone, setViewMenuOnPhone] = useState(false)
    let navigate = useNavigate()
    let size  = useWindowSize()
    let hideMenuInUrls = ['/', '/register', '/restore', '/Account/Login', '/card']
    let [viewMenu, setViewMenu] = useState(!hideMenuInUrls.includes(window.location.pathname))
    const location  = useLocation()

    let [menuItems, setMenuItems] = useState([
      {name: 'publications', display: 'Объявления', haveUnread: false},
      {name: 'news', display: 'Новости', haveUnread: false},
      {name: 'cars', display: 'Мои автомобили', haveUnread: false},
      {name: 'roadHelp', display: 'Помощь на дороге', haveUnread: false},
      {name: 'history', display: 'История ремонтов', haveUnread: false},
      {name: 'calc', display: 'Калькулятор ТО', haveUnread: false},
      {name: 'serviceRequest', display: 'Запись на сервис', haveUnread: false},
      {name: 'messages', display: 'Сообщение дилеру', haveUnread: false},
      {name: 'dealer', display: 'Мой дилер', haveUnread: false},
      {name: 'settings', display: 'Настройки', haveUnread: false},
    ].filter((x) => window.config.viewCalc ? true : x.name !== 'calc').filter((x) => window.location.host.includes('chery') ? true : x.name !== 'publications'))
  
    let logout = () => {
        localStorage.clear()
        window.location = '/'
    };

    let loadIndicators = () => {
        if (!viewMenu) {
            return
        }

        api()
            .getNotificationsForMenu()
            .then((resp) => {
                let temp = menuItems.map((x) => x)

                temp.forEach((x) => x.haveUnread = false);

                if (resp.NewNewsExists) {
                    temp.filter((x) => x.name === 'news')[0].haveUnread = true
                }
                if (resp.NewSurveyExists) {
                    temp.filter((x) => x.name === 'history')[0].haveUnread = true
                }
                if (resp.NewMessagesExists) {
                    temp.filter((x) => x.name === 'messages')[0].haveUnread = true
                }
                if (resp.CarServiceEventsExists || resp.NewSurveySSIExists) {
                    temp.filter((x) => x.name === 'cars')[0].haveUnread = true
                }

                setMenuItems(() => temp)
            })
    }

    useEffect(() => {
        if (!viewMenu) {
            return
        }

        loadIndicators()

        const timer = setInterval(() => {
            loadIndicators()
        }, 30000)

        return () => clearInterval(timer)
    }, [])

    useEffect(() => {
        setViewMenu(!hideMenuInUrls.includes(window.location.pathname))
    }, [location])

    useEffect(() => {
        if (!viewMenu) {
            return
        }

        setActiveMenuItem(window.location.pathname.replace('/', ''));
    }, [lastUpdateTime])

    // Гирлянды на новый год, запуск анимации
    // Внизу есть сам svg для рендера
    // useEffect(() => {
    //   const switchLights = () => {
    //     const garland = document.getElementById('glow');

    //     if (!garland) {
    //       return;
    //     }

    //     const arrayOfLamps = garland.querySelectorAll('path');

    //     if (arrayOfLamps.length) {
    //       arrayOfLamps.forEach((lamp) => {
    //         if (lamp.className.baseVal === 'garland_active') {
    //           lamp.setAttribute('class', '');
    //         } else {
    //           lamp.setAttribute('class', 'garland_active');
    //         }
    //       });
    //     }
    //   };

    //   const interval = setInterval(() => switchLights(), 1500);

    //   return () => {
    //     clearInterval(interval);
    //   };
    // }, []);

    const getImageSrc = (item) => {
        if (window.config.theme === 'Dark' && activeMenuItem === item.name) {
            return `/images/dark/${item.name}-active.svg`
        }
        return `/images/${item.name}${activeMenuItem === item.name ? '-active' : ''}.svg`
    }

    if (!viewMenu) {
        return null
    }
    return (
        <>
            <img
                className={'mobile-menu-btn'}
                src={window.config.theme === 'Dark' ? '/images/dark/menu.svg' : '/images/menu.svg'}
                onClick={() => setViewMenuOnPhone(true)}
            />
            {viewMenuOnPhone && (
                <div style={{
                  height: size.height + 'px',
                  width: '100%',
                  position: "fixed",
                  backgroundColor: 'black',
                  opacity: '0.5',
                  zIndex: '999'
                }} onClick={() => setViewMenuOnPhone(false)}>
            </div>)}

      <div className={'menu-container' + (viewMenuOnPhone ? ' show': '')} style={{height: size.height + 'px', overflowY: (size.height < maxHeightMenu ? 'scroll' : 'inherit')}}>
          <div style={{height: (size.height < maxHeightMenu ? maxHeightMenu : size.height) + 'px', position: 'relative'}}>
              <div className={'header'}>
                  <div className={'logo-container'}><img style={{width: '50%'}} src={'/images/' + window.config.logoImgName}/></div>
              </div>
              <div className={'header-logo-only'}>
                  <img style={{width: '44px'}} src={'/images/' + window.config.logoImgName}/>
              </div>

              {/* Гирлянды на новый год, выше необходимо запустить анимацию */}
              {/* <Garland /> */}

              <div className={'menu-items'}>
                  {menuItems.map((item) => (
                      <div
                          key={item.name}
                          className={'menu-item' + (activeMenuItem === item.name ? ' active' : '')}
                          onClick={() => {
                              setActiveMenuItem(item.name);
                              navigate('/' + item.name);
                              setViewMenuOnPhone(false);
                          }}
                      >
                          <img src={getImageSrc(item)}/>
                          <span className={'label-text'}>{item.display}</span>
                          {item.haveUnread ? <span className={'unread-marker'}></span> : ''}
                      </div>
                  ))}

                  {Boolean(window.config.registerPrivileges) && <div
                      key={'menu-item-privileges'}
                      className={'menu-item'}
                      onClick={()=> {
                          let windowReference = window.open("","_blank");
                          api()
                              .registerPrivilegesRequest((userFirstName + ' ' + userLastName), userGender, userId)
                              .then(resp=>{
                                  if(resp.isOk)
                                      windowReference.location = 'https://exeederclub.ru/?token=' + resp.id;
                                  else {
                                      toast.error('Возникла ошибка при регистрации запроса на привилегии');
                                  }
                              });

                          if (window.config.shortName === 'Exeed.ru') {
                              api()
                                  .getExeederUrl()
                                  .then((response) => {
                                      console.log(`url for exeeder: ${response}`)
                                  })
                                  // .then((response) => {
                                  //     if (response.returnUrl) {
                                  //         window.open(response.returnUrl, "_blank")
                                  //     } else {
                                  //         toast.error('Возникла ошибка при регистрации запроса на привилегии');
                                  //     }
                                  // })
                          }
                      }}
                  >
                      <img src={'/images/privileges.svg'}/>
                      <span className={'label-text'}>Привилегии</span>
                  </div>}

                  <div className={'name-container'}>
                      <div className={'first-name label-text'}>{userFirstName}</div>
                      <div className={'last-name label-text'}>{userLastName}</div>
                  </div>

                  <div className={'menu-item exit-item'} onClick={logout}>
                      <img src={'/images/exit.svg'}/> <span className={'label-text'}>Выход</span>
                  </div>
              </div>
          </div>
      </div>
  </>);
}
